import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {AutoCompletePanel} from './AutoCompletePanel';
import classNames from 'classnames';
import {InputMask} from "primereact/inputmask";
import {Button} from "primereact/button";
import ObjectUtils from "../../../utilities/ObjectUtils";
import {Tooltip} from "primereact/tooltip";
import DomHandler from "../../../utilities/DomHandler";
import UniqueComponentId from "../../../utilities/UniqueComponentId";

export class AutoCompleteInput extends Component {

    static defaultProps = {
        id: null,
        value: null,
        name: null,
        type: 'text',
        suggestions: null,
        field: null,
        scrollHeight: '200px',
        dropdownMode: 'blank',
        minLength: 1,
        delay: 300,
        mask: null,
        style: null,
        className: null,
        inputId: null,
        inputStyle: null,
        inputClassName: null,
        placeholder: null,
        readonly: false,
        disabled: false,
        maxlength: null,
        size: null,
        appendTo: null,
        tabindex: null,
        autoFocus: false,
        tooltip: null,
        tooltipOptions: null,
        ariaLabelledBy: null,
        completeMethod: null,
        itemTemplate: null,
        selectedItemTemplate: null,
        onChange: null,
        onFocus: null,
        onBlur: null,
        onSelect: null,
        onUnselect: null,
        onDropdownClick: null,
        onClick: null,
        onDblClick: null,
        onMouseDown: null,
        onKeyUp: null,
        onKeyPress: null,
        onContextMenu: null,
        onClear: null
    }

    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.any,
        name: PropTypes.string,
        type: PropTypes.string,
        suggestions: PropTypes.array,
        field: PropTypes.string,
        scrollHeight: PropTypes.string,
        dropdown: PropTypes.bool,
        dropdownMode: PropTypes.string,
        multiple: PropTypes.bool,
        minLength: PropTypes.number,
        delay: PropTypes.number,
        style: PropTypes.object,
        className: PropTypes.string,
        inputId: PropTypes.string,
        inputStyle: PropTypes.object,
        inputClassName: PropTypes.string,
        placeholder: PropTypes.string,
        mask: PropTypes.string,
        readonly: PropTypes.bool,
        disabled: PropTypes.bool,
        maxlength: PropTypes.number,
        size: PropTypes.number,
        appendTo: PropTypes.any,
        tabindex: PropTypes.number,
        autoFocus: PropTypes.bool,
        tooltip: PropTypes.string,
        tooltipOptions: PropTypes.object,
        ariaLabelledBy: PropTypes.string,
        completeMethod: PropTypes.func,
        itemTemplate: PropTypes.func,
        selectedItemTemplate: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onSelect: PropTypes.func,
        onUnselect: PropTypes.func,
        onDropdownClick: PropTypes.func,
        onClick: PropTypes.func,
        onDblClick: PropTypes.func,
        onMouseDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onKeyPress: PropTypes.func,
        onContextMenu: PropTypes.func,
        onClear: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onInputClick = this.onInputClick.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onMultiContainerClick = this.onMultiContainerClick.bind(this);
        this.onMultiInputFocus = this.onMultiInputFocus.bind(this);
        this.onMultiInputBlur = this.onMultiInputBlur.bind(this);
        this.selectItem = this.selectItem.bind(this);

        this.listId = UniqueComponentId() + '_list';
    }

    onInputChange(event) {
        //Cancel the search request if user types within the timeout
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        let query = event.target.value;
        if (!this.props.multiple) {
            this.updateModel(event, query);
        }

        if (!query || query.length === 0) {
            this.hidePanel();
            if (this.props.onClear) {
                this.props.onClear(event);
            }
        }
        else {
            if (query.length >= this.props.minLength) {
                this.timeout = setTimeout(() => {
                    this.search(event, query, 'input');
                }, this.props.delay);
            }
            else {
                this.hidePanel();
            }
        }
    }

    onInputClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    search(event, query, source) {
        //allow empty string but not undefined or null
        if (query === undefined || query === null) {
            return;
        }

        //do not search blank values on input change
        if (source === 'input' && query.trim().length === 0) {
            return;
        }

        if (this.props.completeMethod) {
            this.searching = true;
            this.showLoader();
            this.props.completeMethod({
                originalEvent: event,
                query: query
            });
        }
    }

    selectItem(event, option) {
        this.props.onSelect({
            originalEvent: event,
            value: option
        })
        this.hidePanel();
    }

    updateModel(event, value) {
        if (this.props.onChange) {
            this.props.onChange({
                originalEvent: event,
                value: value,
                stopPropagation : () =>{},
                preventDefault : () =>{},
                target: {
                    name: this.props.name,
                    id: this.props.id,
                    value: value
                }
            });
        }

        this.ariaSelected = value;
    }

    updateInputField(value) {
        this.inputEl.value = value;
    }

    showPanel() {
        if (this.focus) {
            this.alignPanel();

            if (this.panel && this.panel.element && !this.panel.element.offsetParent) {
                this.panel.element.style.zIndex = String(DomHandler.generateZIndex());
                this.panel.element.style.display = "block";

                setTimeout(() => {
                    if (this.panel && this.panel.element) {
                        DomHandler.addClass(this.panel.element, 'p-input-overlay-visible');
                        DomHandler.removeClass(this.panel.element, 'p-input-overlay-hidden');
                    }
                }, 1);

                this.alignPanel();
                this.bindDocumentClickListener();
            }
        }
    }

    alignPanel() {
        if (this.panel && this.panel.element && this.panel.element.offsetParent) {
            let target = this.props.multiple ? this.multiContainer : this.inputEl;

            if (this.props.appendTo) {
                this.panel.element.style.minWidth = DomHandler.getWidth(target) + 'px';
                DomHandler.absolutePosition(this.panel.element, target);
            }
            else {
                DomHandler.relativePosition(this.panel.element, target);
            }
        }
    }

    hidePanel() {
        if (this.panel && this.panel.element) {
            DomHandler.addClass(this.panel.element, 'p-input-overlay-hidden');
            DomHandler.removeClass(this.panel.element, 'p-input-overlay-visible');

            setTimeout(() => {
                if (this.panel && this.panel.element) {
                    this.panel.element.style.display = 'none';
                    DomHandler.removeClass(this.panel.element, 'p-input-overlay-hidden');
                }
            }, 150);

            this.unbindDocumentClickListener();
        }
    }

    removeItem(event, index) {
        let removedValue = this.props.value[index];
        let newValue = this.props.value.filter((val, i) => (index !== i));
        this.updateModel(event, newValue);

        if (this.props.onUnselect) {
            this.props.onUnselect({
                originalEvent: event,
                value: removedValue
            })
        }
    }

    onInputKeyDown(event) {
        if (!(this.panel && this.panel.element)) {
            return;
        }
        if (this.isPanelVisible()) {
            let highlightItem = DomHandler.findSingle(this.panel.element, 'li.p-highlight');

            switch(event.which) {
                //down
                case 40:
                    if (highlightItem) {
                        let nextElement = highlightItem.nextElementSibling;
                        if (nextElement) {
                            DomHandler.addClass(nextElement, 'p-highlight');
                            DomHandler.removeClass(highlightItem, 'p-highlight');
                            DomHandler.scrollInView(this.panel.element, nextElement);
                        }
                    }
                    else {
                        DomHandler.addClass(this.panel.element.firstChild.firstChild, 'p-highlight');
                    }

                    event.preventDefault();
                    break;

                //up
                case 38:
                    if (highlightItem) {
                        let previousElement = highlightItem.previousElementSibling;
                        if (previousElement) {
                            DomHandler.addClass(previousElement, 'p-highlight');
                            DomHandler.removeClass(highlightItem, 'p-highlight');
                            DomHandler.scrollInView(this.panel.element, previousElement);
                        }
                    }

                    event.preventDefault();
                    break;

                //enter,tab
                case 13:
                    if (highlightItem) {
                        this.selectItem(event, this.props.suggestions[DomHandler.index(highlightItem)]);
                        this.hidePanel();
                    }

                    event.preventDefault();
                    break;

                //escape
                case 27:
                    this.hidePanel();
                    event.preventDefault();
                    break;

                //tab
                case 9:
                    if (highlightItem) {
                        this.selectItem(event, this.props.suggestions[DomHandler.index(highlightItem)]);
                    }

                    this.hidePanel();
                    break;

                default:
                    break;
            }
        }
    }

    onInputFocus(event) {
        if (this.container) {
            this.focus = true;

            if (this.props.onFocus) {
                this.props.onFocus(event);
            }

            DomHandler.addClass(this.container, 'p-inputwrapper-focus');
        }
    }

    onInputBlur(event) {
        this.focus = false;

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        DomHandler.removeClass(this.container, 'p-inputwrapper-focus');
    }

    onMultiContainerClick(event) {
        this.inputEl.focus();

        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    onMultiInputFocus(event) {
        this.onInputFocus(event);
        DomHandler.addClass(this.multiContainer, 'p-focus');
    }

    onMultiInputBlur(event) {
        this.onInputBlur(event);
        DomHandler.removeClass(this.multiContainer, 'p-focus');
    }

    isSelected(val) {
        let selected = false;
        if (this.props.value && this.props.value.length) {
            for (let i = 0; i < this.props.value.length; i++) {
                if (ObjectUtils.equals(this.props.value[i], val)) {
                    selected = true;
                    break;
                }
            }
        }

        return selected;
    }

    findOptionIndex(option) {
        let index = -1;
        if (this.suggestions) {
            for (let i = 0; i < this.suggestions.length; i++) {
                if (ObjectUtils.equals(option, this.suggestions[i])) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    componentDidMount() {
        if (this.props.autoFocus && this.inputEl) {
            this.inputEl.focus();
        }

        if (this.props.tooltip) {
            this.renderTooltip();
        }

        this.hidePanel()
    }

    componentWillUnmount() {
        this.unbindDocumentClickListener();

        if (this.tooltip) {
            this.tooltip.destroy();
            this.tooltip = null;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.suggestions !== this.props.suggestions && this.searching) {
            if (this.props.suggestions && this.props.suggestions.length)
                this.showPanel();
            else
                this.hidePanel();

            this.hideLoader();

            this.searching = false;
        }

        if (this.inputEl && !this.props.multiple) {
            this.updateInputField(this.props.value);
        }

        if (prevProps.tooltip !== this.props.tooltip) {
            if (this.tooltip)
                this.tooltip.updateContent(this.props.tooltip);
            else
                this.renderTooltip();
        }
    }

    showLoader() {
        if (this.loader) {
            this.loader.style.visibility = 'visible';
        };
    }

    hideLoader() {
        if (this.loader) {
            this.loader.style.visibility = 'hidden';
        };
    }

    renderTooltip() {
        this.tooltip = new Tooltip({
            target: this.container,
            content: this.props.tooltip,
            options: this.props.tooltipOptions
        });
    }

    renderMaskAutoComplete() {
        const inputClassName = classNames('p-autocomplete-input', this.props.inputClassName, {
            'p-autocomplete-dd-input': this.props.dropdown
        });

        return (
            <InputMask mask={this.props.mask} ref={(el) => this.inputEl = ReactDOM.findDOMNode(el)} id={this.props.inputId} type={this.props.type} name={this.props.name}
                       value={this.props.value} role="searchbox" aria-autocomplete="list" aria-controls={this.listId}
                       aria-labelledby={this.props.ariaLabelledBy} className={inputClassName} style={this.props.inputStyle} autoComplete="off"
                       readOnly={this.props.readonly} disabled={this.props.disabled} placeholder={this.props.placeholder} size={this.props.size}
                       maxLength={this.props.maxlength} tabIndex={this.props.tabindex}
                       onBlur={this.onInputBlur} onFocus={this.onInputFocus}
                       onChange={this.onInputChange}
                       onMouseDown={this.props.onMouseDown} onKeyUp={this.props.onKeyUp} onKeyDown={this.onInputKeyDown}
                       onKeyPress={this.props.onKeyPress} onContextMenu={this.props.onContextMenu}
                       onClick={this.onInputClick} onDoubleClick={this.props.onDblClick}
                       autoClear={this.props.autoClear}
                       slotChar={this.props.slotChar}/>
        );
    }

    renderLoader() {
        return (
            <i ref={(el) => this.loader = el} className="p-autocomplete-loader pi pi-spinner pi-spin" style={{visibility: 'hidden', marginRight: "10px"}}></i>
        );
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.documentClickListener = (event) => {
                if (event.which === 3) {
                    return;
                }

                if (this.isOutsideClicked(event)) {
                    this.hidePanel();
                }
            };

            document.addEventListener('click', this.documentClickListener);
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            document.removeEventListener('click', this.documentClickListener);
            this.documentClickListener = null;
        }
    }

    isPanelVisible() {
        return this.panel && this.panel.element && this.panel.element.offsetParent != null;
    }

    isOutsideClicked(event) {
        return this.container && !(this.container.isSameNode(event.target) || this.container.contains(event.target)
            || (this.panel && this.panel.element && this.panel.element.contains(event.target)));
    }

    render() {
        let input;
        let className = classNames('p-autocomplete p-component', this.props.className, {
            'p-autocomplete-multiple': this.props.multiple,
            'p-inputwrapper-filled': this.props.value,
            'p-inputwrapper-focus': this.focus
        });
        let loader = this.renderLoader();

        input = this.renderMaskAutoComplete();

        return (
            <span ref={(el) => this.container = el} id={this.props.id} style={this.props.style} className={className} aria-haspopup="listbox"
                  aria-expanded={this.panel && this.isPanelVisible()} aria-owns={this.listId}>
                {input}
                {loader}
                <AutoCompletePanel ref={(el) => this.panel = el} suggestions={this.props.suggestions} field={this.props.field} listId={this.listId}
                                   appendTo={this.props.appendTo} itemTemplate={this.props.itemTemplate} onItemClick={this.selectItem} ariaSelected={this.ariaSelected}/>
            </span>
        );
    }
}