import { withErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

const sentryConfigs = {
  'development': {
    enabled: false,
    environment: 'development'
  },
  'stage': {
    enabled: true,
    environment: 'stage'
  },
  'production': {
    enabled: true,
    environment: 'production'
  },
};

let config = sentryConfigs.production;
var BUILD_VERSION;

if (/(.*localhost.*)|127.0.0.1.*/.test(window.location.host)) {
  config = sentryConfigs.development;
} else if (window.location.host.includes('stage')) {
  config = sentryConfigs.stage;
}

class SentryErrorHandler {
  sentryInstance = null;
  sentryEnabled = false;

  constructor({
    enabled,
    environment
  }: any) {
    this.sentryEnabled = enabled;
    if (enabled) {
      Sentry.init({
        dsn: "https://e0b8cb1dd6604cec90c1176b0db747b4@o4504008837365760.ingest.sentry.io/4504008944975872",
        integrations: [
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
        ],
        environment,
        normalizeDepth: 10,
        release: `vulacoin-fe-${BUILD_VERSION ?? 'dev'}`,
        beforeSend: (event) => {
          if (this.shouldReport(event?.exception?.values?.[0]?.value)) {
            return event;
          }

          return null;
        }
      });
    }
  }

  ignoredErrors: string[] = [
    "Unexpected token '<'",
    "Unexpected token <",
    "Cannot read properties of undefined (reading 'noCvv')",
    "x5onSkinSwitch is not defined",
    "Request aborted",
    "Canvas is already in use. Chart with ID "
  ];

  shouldReport(message?: string) {
    let send = true;

    this.ignoredErrors.forEach(ignoredError => {
      if (message?.includes(ignoredError)) {
        send = false;
      }
    });

    if (message?.includes("Loading chunk") || message?.includes("Loading CSS chunk")) {
      send = false;
      window.location.reload();
    }
    return send;
  }

  sentryErrorHandler(error: Error, info: { componentStack: string }, componentName: string) {
    Sentry.withScope((scope) => {
      const extras = {
        component_name: componentName
      };

      Object.entries(extras).forEach(pair => {
        scope.setExtra(pair[0], pair[1]);
      })
      Sentry.captureException(error, scope);
    });
  }

  consoleErrorHandler(error: Error, info: { componentStack: string }, componentName: string) {
    console.error(error, componentName)
  }

  errorHandler(component: any, componentName: string) {
    return withErrorBoundary(component, {
      fallbackRender: () => null,
      onError: (e, i) => { this.sentryEnabled ? this.sentryErrorHandler(e, i, componentName) : this.consoleErrorHandler(e, i, componentName) }
    });
  }
}

export default new SentryErrorHandler(config);
