import { Dropdown } from 'primereact/dropdown';
import { countries } from '../../utilities/countries';
import { InputMask } from 'primereact/inputmask';
import { useTranslation } from 'react-i18next';
import { AutoCompleteInput } from '../../common/Components/Base/AutoCompleteInput';
import { getExample } from 'awesome-phonenumber';

const CellInput = (props) => {
  const { t } = useTranslation();
  const selectedCountryTemplate = (option) => {
    return (
      <b>
        <img
          src="/assets/demo/images/flags/flag_placeholder.png"
          className={option?.flag}
          alt="N/A"
        />
      </b>
    );
  };

  const countryOptionTemplate = (option) => {
    return (
      <b>
        <img
          alt="N/A"
          src="/assets/demo/images/flags/flag_placeholder.png"
          className={option.flag}
        />{' '}
        ({option.code})
      </b>
    );
  };

  const getMask = () => {
    let mask = '';
    const country = countries.filter((c) => c.code === props.code.code);
    const region = country[0].flag.slice(-2).toUpperCase();
    const exampleNumber = getExample(region, 'mobile').getNumber('national');
    for (let i = 0; i < exampleNumber.replace(/\s/g, '').length; i++) {
      mask = mask + '9';
    }
    return mask;
  };

  const label =
    typeof props.label === 'string'
      ? props.label
      : `${t('enter_amount_to_send')}:`;

  return (
    <div className="p-col-12" style={props.containerStyle}>
      <style>{`
          .p-autocomplete-input:hover,
          .p-autocomplete-input:focus,
          .p-autocomplete-input:active {
            border-color: ${props.merchantColour} !important;
          }
        `}</style>
      {props.omitHeading ? null : <h6>{label}</h6>}
      <div className={`${props.className} p-inputgroup`}>
        <span className="p-inputgroup-addon" style={{ padding: '0' }}>
          <Dropdown
            className="phone-dropdown"
            value={props.code}
            options={countries}
            onChange={(e) => {
              props.setCode(e.value);
            }}
            valueTemplate={selectedCountryTemplate}
            optionLabel="code"
            itemTemplate={countryOptionTemplate}
            filter
            filterBy="name"
          />
        </span>
        {props.autocomplete ? (
          <AutoCompleteInput
            mask={getMask()}
            suggestions={props.suggestions}
            completeMethod={props.search}
            value={props.number}
            autoClear={false}
            type="tel"
            slotChar=""
            name="cell_number"
            onChange={(e) =>
              props.setNumber(e.target.value?.replace(/\s/g, ''))
            }
            onSelect={(e) => props.setNumber(e.value?.replace(/\s/g, ''))}
            placeholder={props.className ? '' : 'Enter phone number'}
            className={
              props.error ? `${props.fontSize} p-invalid` : `${props.fontSize}`
            }
            style={{ width: '100%' }}
          />
        ) : (
          <>
          <style>
            {`.p-inputmask.p-inputtext:hover {
              border-color: ${props.merchantColour || "#3d5cff"}
            }
            .p-inputtext.p-component.p-inputmask.p-inputtext:focus-within
            {
              border-color: ${props.merchantColour || "#3d5cff"}
            }
            `}
          </style>
          <InputMask
            value={props.number}
            onChange={(e) =>
              props.setNumber(e.target.value?.replace(/\s/g, ''))
            }
            mask={getMask()}
            autoClear={false}
            disabled={props.disabled}
            onBlur={(e) => props.setNumber(e.target.value?.replace(/\s/g, ''))}
            type="tel"
            slotChar=""
            name='cell_number'
            autoComplete="tel"
            className={
              props.error ? `${props.fontSize} p-invalid` : `${props.fontSize}`
            }
            placeholder="Enter phone number"
          />
          </>
        )}
      </div>
      {props.error ? (
        <small className="p-error p-d-block">{props.error}</small>
      ) : null}
    </div>
  );
};

export default CellInput;
