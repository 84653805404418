import apiClient from "../../../api-config/axios.config";
import { handleInterceptor } from "../../../utilities/handleHTTPInterceptor";

const login = (details) => {
  return apiClient
    .post('admin/login', details)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'login failed');
    });
};

const getCookie = () => {
  return apiClient
    .get("sanctum/csrf-cookie")
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const getUser = async (type, paymentId) => {
  return apiClient
    .get(`v2/auth/${type}/user`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'whoAmI failed');
    });
}

const whoAmI = (admin, isRetailer, paymentId, useNewAuth) => {

  if (!(admin || isRetailer) && useNewAuth) {
    return getUser(admin || isRetailer ? 'admin' : 'clients', paymentId);
  }
  
  const params = {};
  if(paymentId && !admin && !isRetailer) {
    params.phasedPaymentId = paymentId
  }
  return apiClient
    .get(
      (admin || isRetailer) ? "admin/whoami" : "client/whoami", {params}
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'whoAmI failed');
    });
};

const logout = () => {
  return apiClient
    .post("admin/logout")
    .then(() => {
      return true;
    })
    .catch((error) => {
      return handleInterceptor(error, 'logout failed');
    });
};

export { login, getCookie, whoAmI, logout };
