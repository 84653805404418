export const getUserMerchantColour = (user, defaultValue) => {
  if (!user) {
    return defaultValue;
  }
  if (user?.details?.primary_color) {
    return user.details.primary_color;
  } else if (user?.currentRetailer?.primary_color) {
    return user.currentRetailer.primary_color;
  } else if (
    user?.details?.retailer?.primary_color
  ) {
    return user.details.retailer.primary_color;
  }
  return defaultValue;
};

export const getMerchantColour = (
  merchant,
  defaultValue,
  addGradient = false,
) => {
  if (merchant?.primary_color) {
    if (addGradient) {
      return `linear-gradient(0deg, ${merchant.primary_color} 0%, ${merchant.primary_color}95 100%)`;
    }
    return merchant.primary_color;
  }
  return defaultValue;
};
