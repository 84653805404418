import apiClient from "../../../api-config/axios.config";
import { handleInterceptor } from "../../../utilities/handleHTTPInterceptor";


const getClientNotifications = () => {
  return apiClient
    .get('/v2/notifications/client/pending', {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'getClientNotifications failed');
    });
};

export { getClientNotifications };
