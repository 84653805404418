import apiClient from "../../../api-config/axios.config";
import { handleInterceptor } from "../../../utilities/handleHTTPInterceptor";

const createTicket = (request) => {
  return apiClient
    .post("client/support", request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'createTicket failed');
    });
};

export { createTicket };
