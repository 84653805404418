import apiClient from '../../../api-config/axios.config';
import { handleInterceptor } from "../../../utilities/handleHTTPInterceptor";

const requestPayment = (request) => {
  return apiClient
    .post('client/payments/requestTopUpCheckOut', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestPayment failed');
    });
};

const transferToRetailer = (request) => {
  return apiClient
    .post('client/payments/transferBalanceToRetailer', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'transferToRetailer failed');
    });
};

const getDiscountAmounts = (params) => {
  return apiClient
    .get('client/payments/discountAmounts', { params })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'getDiscountAmounts failed');
    });
};

const getMinimumAmounts = (params) => {
  return apiClient
    .get('client/payments/minimumAmounts', { params })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'getMinimumAmounts failed');
    });
};

const getInterWallet = () => {
  return apiClient
    .get('client/inter-wallet')
    .then((response) => response.data.data)
    .catch((error) => {
      return handleInterceptor(error, 'getInterWallet failed');
    });
};

const checkStatus = (
  request,
  analytics = { ping: 0, jitter: 0, download: 0 },
  eft = false,
  stitchStatus = '',
  nc,
  origin,
) => {
  const p = `analytics[ping]=${encodeURIComponent(analytics.ping)}`;
  const j = `analytics[jitter]=${encodeURIComponent(analytics.jitter)}`;
  const d = `analytics[download]=${encodeURIComponent(analytics.download)}`;
  const ncVal = nc ? 1 : 0;
  const aString = `${p}&${j}&${d}`;
  return apiClient
    .get(
      `client/payments/check-status?payment_identifier=${request}&${aString}&eft=${eft}&stitch_status=${stitchStatus}&nc=${ncVal}&origin=${origin ? origin : 'captive'}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'checkStatus failed');
    });
};

const requestExternalPayment = (request) => {
  return apiClient
    .post('client/payments/request-express-payment', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestExternalPayment failed');
    });
};

const requestOwnAmountPayment = (request) => {
  return apiClient
    .post('client/payments/own-amount-payment-request', request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestOwnAmountPayment failed');
    });
};

const requestProductPayment = (request) => {
  return apiClient
    .post('client/payments/product-payment-request', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestProductPayment failed');
    });
};

const requestMarketplacePayment = (request) => {
  return apiClient
    .post('client/payments/marketplace-payment-request', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestMarketplacePayment failed');
    });
};

const requestSendPayment = (request) => {
  return apiClient
    .post('client/payments/send-payment-request', request)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestSendPayment failed');
    });
};

const cancelExternalPayment = (request) => {
  apiClient.post('client/payments/cancel-express-payment', request);
};

const requestStitchPayment = (request) => {
  return apiClient.post('client/payments/eft/stitch', request)
    .catch((error) => {
      console.error(error);
    });
};

const getDiscountData = async (params) => {
  return apiClient.get('client/payments/discount-data', { params })
    .catch((error) => {
      console.error(error);
    });
};

const getPaymentRequestedFrom = (id) => {
  return apiClient.get(`/client/payments/payment-request-hostname/${id}`)
      .then(res => res.data.data);
}

export {
  requestPayment,
  checkStatus,
  requestExternalPayment,
  cancelExternalPayment,
  getDiscountAmounts,
  getMinimumAmounts,
  getDiscountData,
  getInterWallet,
  transferToRetailer,
  requestProductPayment,
  requestMarketplacePayment,
  requestSendPayment,
  requestStitchPayment,
  requestOwnAmountPayment,
  getPaymentRequestedFrom
};
