import { createContext } from 'react';
import { AdminUser, ClientUser } from '../common/definitions';

export type ClientContext = {
  user?: ClientUser,
  setUser?: (user: ClientUser | any) => void
};

export type AdminContext = {
  user?: AdminUser,
  setUser?: (user: AdminUser | any) => void
};

const UserContext = createContext<ClientContext | AdminContext>({});

export default UserContext;
