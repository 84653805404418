import axios, { CancelTokenSource } from "axios";
import { jwt } from "./jwt-session";

const apiClient = axios.create({
  baseURL: "/api/",
});

let _cancelSource;
export const setApiClientCancelSource = (source) => {
  _cancelSource = source;
}

apiClient.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params['hostname'] = window.location.hostname;
  if(jwt.token()) {
    config.headers['Authorization'] = jwt.bearerToken();
  }

  if(_cancelSource) {
    config.cancelToken = _cancelSource.token;
    setApiClientCancelSource(null);
  }

  return config;
});

const setJwtFromResponse = (response) => {
  if (response.headers['x-jwt']) {
    jwt.setToken(response.headers['x-jwt']);
  }
  else if (response && response.data && response.data && response.data.jwt_token) {
    jwt.setToken(response.data.jwt_token);
    delete response.data.jwt_token;
  } else if (response && response.data && response.data.data && response.data.data.jwt_token) {
    jwt.setToken(response.data.data.jwt_token);
    delete response.data.data.jwt_token;
  }
};

apiClient.interceptors.response.use((response) => {
  // automatically set the token if it's part of the response and delete it from
  // response seeing as convention isn't always followed in response structure.
  setJwtFromResponse(response);


  if(response.config.url.includes('logout')) {
    jwt.clear();
  }
  return response;
});

export default apiClient;
