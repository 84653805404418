import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router';
import { getUserMerchantColour } from '../../../utilities/merchantCustomization';
import { DailyRecapNotification } from './DailyRecapNotification';

import './PushNotification.scss';

export const PushNotification = (props) => {
  const history = useHistory();
  const [oldNotification, setOldNotification] = useState(null);

  const getNotification = () => {
    return props.notification ?? oldNotification;
  }

  const data = getNotification()?.data ? JSON.parse(getNotification()?.data) : [];

  const dismiss = (e) => {
    props.setShow(false);
    setOldNotification(props.notification);
    props.setNotification(null);
    e.stopPropagation();
  }

  const onClick = () => {
    if (data?.link) {
      history.push({
        pathname: data.link,
      });
    }
    dismiss();
  }

  useEffect(() => {
    if (!props?.user?.details) {
      props.setShow(false);
      props.setNotification(null);
    }
  }, [props.user]);

  const customPushNotification = () => {
    switch (data?.component) {
      case 'daily-recap':
        return <DailyRecapNotification
          notification={getNotification()}
          setShow={props.setShow}
          setNotification={props.setNotification}
          merchantColor={props.merchantColor}
          user={props.user}
          show={props.show}
          setOldNotification={setOldNotification}
        />;
      default: return defaultPushNotification();
    }
  }

  const defaultPushNotification = () => {
    return (
      <>
        <div
          onClick={() => {onClick()}}
          className={props.show ? "push-notification push-notification-show" : "push-notification push-notification-hide"}
          style={{borderColor: data?.background_color ? data?.background_color : props.merchantColor}}
        >
          <div
            className="header-container"
            style={{background: data?.background_color ? data?.background_color : getUserMerchantColour(props.user)}}
          >
            <div className="header-icon">
              {data?.emoji ?
                <div className="inner-header-icon"><span style={{fontSize: '25px'}}>&#x1F680;</span></div>
                :
                <div className="inner-header-icon"><i id="icon" className="pi pi-calendar"/></div>
              }
            </div>
          <p className="header-text">
              {data?.header ?? 'Notification'}
          </p>
          <Button
            className= "notification-close-btn"
            icon="pi pi-plus"
            onClick={(e) => {dismiss(e)}}
          />
          </div>
          <div className="message-container" style={{borderColor: data?.background_color ? data?.background_color : getUserMerchantColour(props.user)}}>
            <p className="message-text">
                {getNotification()?.message || data?.message}
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    customPushNotification()
  );
};

export default PushNotification;
