import moment from "moment";

class JwtSession {

    constructor() {
        this._data = {};
        this._user = {};
        this._jwt = undefined;
    }

    setToken(jwt) {
        this._jwt = jwt
        this.parseAndSet(jwt);
    }

    token() {
        return this._jwt;
    }

    clear() {
        this._data = {};
        this._user = {};
        this._jwt = undefined;
    }

    parseAndSet(jwt) {
        this._jwt = jwt;
        try{
            const base64Url = jwt.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const json = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))

            this._data = JSON.parse(json);
            this._user = this._data.usr; //
        } catch(e) {
            throw new Error('Could not parse JWT.');
        }
    }

    expired() {
        return Math.floor(Date.now()) >= this._data.exp * 1000;
    }

    expiresAt() {
        return new Date(this._data.exp * 1000);
    }

    user() {
        return this._user;
    }

    data() {
        return this._data;
    }

    id() {
       return this._data.sub;
    }

    bearerToken() {
        return `Bearer ${this._jwt}`;
    }

    async refresh(role) {

    }

    has2FA() {
        return this._user?.has2fa;
    }

    verified2FA() {
        return moment(this._data?.['2fa']).isAfter(moment());
    }
}

const jwt = new JwtSession();

export { jwt };