import { httpInterceptor } from "../api-config/interceptor";

export const handleInterceptor = (error, message, axios, axiosReturnValue) => {
  if (axios && axios.isCancel(error)) {
    return axiosReturnValue;
  }
  if(error.response) {
    return httpInterceptor(
      error.response.status,
      error.response.data.message,
      error.response.data,
    );
  } else {
      console.error(error);
      return httpInterceptor(
        0, // unknown preflight error
        message,
      );
  }
};
